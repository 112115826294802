<template>
  <div>
    <banner title="Request a Song" />
    <div class="requests">
      <v-dialog
        max-width="80%"
        v-model="dialog"
        width="600"
        overlay-color="blue"
        overlay-opacity="0.5"
        scrollable
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="blue lighten-2"
            style="margin-left: 50%; transform: translateX(-50%)"
            dark
            text
            v-bind="attrs"
            v-on="on"
            >See Top 10 Requests</v-btn
          >
        </template>

        <v-card height="550">
          <v-card-title style="border-bottom: 1px solid black">
            <v-row class="pa-0 ma-0">
              <v-col cols="10">Top 10 Requests</v-col>
              <v-col class="ml-auto" cols="2">
                <v-btn small fab @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-timeline v-if="topten.length" align-top dense>
              <v-timeline-item v-for="(i, index) in topten" :key="i.songid" :color="randomColor()">
                <v-row class="pt-1" @click="viewSongInfo(i)" style="cursor: pointer">
                  <v-col cols="auto">
                    <v-avatar tile class="ml-2">
                      <v-img :src="itemImg(i)" :alt="i.title"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <strong>{{ index + 1 }}</strong
                    >.
                    <div class="font-weight-bold">{{ i.artist | separate }}</div>
                    <div class="caption">{{ i.title }}</div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--<div class="text-center">Our regular catalog will not be
      available until after the holidays. Our holiday catalog is available now.
      Enjoy this season with music to celebrate the birth of our Saviour!</div>-->
      <v-card class="mx-auto mt-8 mb-8" max-width="600" max-height="800" elevation="2">
        <v-card-text class="px-16">
          <v-text-field
            autocomplete="false"
            placeholder="Enter title or artist name..."
            v-model="searchTerm"
            ref="searchBar"
            clearable
            @click:clear="clearSearchResults"
          ></v-text-field>
        </v-card-text>
        <v-card-text style="position: relative">
          <v-overlay absolute :value="searchLoading">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-overlay>
          <v-list v-if="searchResults.length" height="600" style="overflow-y: auto">
            <v-list-item
              v-for="item in searchResults"
              :key="item.songid"
              class="song_list"
              @click="viewSongInfo(item)"
              :style="isMobile ? 'cursor: pointer' : ''"
            >
              <v-avatar class="mr-2">
                <v-img :src="itemImg(item)" :alt="item.album"></v-img>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  style="cursor: pointer"
                  id="list_link"
                  :style="isMobile ? 'font-size:0.8rem;' : 'font-size:1.1rem'"
                  >{{ item.title }}</v-list-item-title
                >
                <v-tooltip bottom v-if="!isMobile" disabled>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-subtitle
                      v-on="on"
                      v-bind="attrs"
                      style="cursor: pointer"
                      :style="isMobile ? 'font-size:0.8rem;' : 'font-size:1.1rem'"
                    >
                      <!-- @click="searchTerm = item.artist" -->
                      {{ item.artist }}
                    </v-list-item-subtitle>
                  </template>
                  <span>Search Artist {{ item.artist | separate }}?</span>
                </v-tooltip>
                <v-list-item-subtitle
                  v-else
                  :style="isMobile ? 'font-size:0.8rem;' : 'font-size:1.1rem'"
                  >{{ item.artist | separate }}</v-list-item-subtitle
                >
                <v-list-item-subtitle style="cursor: pointer" class="text-caption">{{
                  item.album
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
          <v-list
            v-else-if="hasSearched && !searchLoading && searchTermSnapshot !== ''"
            height="600"
            style="overflow-y: auto"
          >
            <v-list-item class="song_list">
              <v-list-item-content>
                <v-list-item-title :style="isMobile ? 'font-size:0.8rem;' : 'font-size:1.1rem'"
                  >No results found for '{{ searchTermSnapshot }}'</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card
        v-if="myfavorites.length"
        class="mx-auto mb-3 mt-16 pb-3 pt-3"
        max-width="400"
        elevation="0"
      >
        <v-list dense outlined elevation="2" two-line>
          <v-card-title>Your Favorites</v-card-title>
          <v-divider></v-divider>
          <v-list-item-group v-for="f in myfavorites.favorites" :key="f.songid">
            <v-list-item class="text-left pl-2" @click="viewSongInfo(f)">
              <v-list-item-avatar>
                <v-img :src="itemImg(f)" :alt="f.title"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ f.artist | separate }}</v-list-item-title>
                <v-list-item-subtitle>{{ f.title }}</v-list-item-subtitle>
                <v-divider inset></v-divider>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
      <v-card v-if="recentSearches.length" width="600" class="mx-auto mt-4" elevation="0">
        <v-card-title>Recently Searched</v-card-title>
        <v-divider inset></v-divider>
        <v-row justify="start">
          <v-col col="2" v-for="(term, i) in recentSearches" :key="i">
            <v-chip-group column>
              <v-chip
                v-if="term.search"
                @click="searchTerm = term.search"
                :color="term.color"
                class="ma-2"
                text-color="white"
                >{{ term.search }}</v-chip
              >
            </v-chip-group>
          </v-col>
        </v-row>
      </v-card>
      <v-snackbar
        v-if="requestHeader"
        :timeout="requestCode !== '200' ? 3000 : -1"
        :color="requestHeader === 'Request Successful' ? 'success' : 'warning'"
        v-model="snackbar"
      >
        <div>
          <div class="white--text text-h5">{{ requestHeader }}</div>
          <div class="black--text text-h6" v-if="requestBody">{{ requestBody }}</div>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="closeSnackbar">Close</v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import Banner from "../components/Banner.vue";

export default {
  name: "Requests",
  components: { Banner },
  data: () => ({
    snackbar: false,
    dialog: false,
    searchTermSnapshot: "",
  }),
  metaInfo() {
    return {
      title: "Request Page",

      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Have a favorite Song? Search by title, artist, or album. Our library has over 7000 songs and growing!",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Have a favorite Song? Search by title, artist, or album. Our library has over 7000 songs and growing!",
        },
        { vmid: "og:title", property: "og:title", content: "GraceWay Request Page" },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/requests",
        },
      ],
    };
  },
  watch: {
    $route(to) {
      if (to?.params?.query) {
        this.searchTerm = this.$route.params.query;
      }
    },
    searchLoading(val) {
      if (val) {
        this.$refs.searchBar.blur();
      }
    },
    recentSearches() {
      this.saveSearch();
    },
    searchTerm(val) {
      if (!val) {
        this.searchResults = [];
      } else if (val.length >= 3) {
        this.search(val);
      }
    },
    requestHeader(val) {
      if (val) {
        this.snackbar = true;
        setTimeout(() => {
          this.closeSnackbar();
        }, 3000);
      }
    },
  },
  computed: {
    topten() {
      return this.$store.state.songRequests.topten;
    },
    hasSearched() {
      return this.$store.state.songRequests.hasSearched;
    },
    myfavorites() {
      return this.$store.state.mySongs;
    },
    recentSearches: {
      get() {
        return this.$store.state.songRequests.recentSearches;
      },
      set(recentSearches) {
        this.$store.dispatch("songRequests/setRecentSearches", recentSearches);
      },
    },
    searchTerm: {
      get() {
        return this.$store.state.songRequests.searchTerm;
      },
      set(searchTerm) {
        this.$store.dispatch("songRequests/setSearchTerm", searchTerm);
      },
    },
    searchResults: {
      get() {
        return this.$store.state.songRequests.searchResults;
      },
      set(val) {
        this.$store.dispatch("songRequests/setSearchResults", val);
      },
    },
    requestHeader() {
      return this.$store.state.songRequests.requestHeader;
    },
    requestBody() {
      return this.$store.state.songRequests.requestBody;
    },
    requestCode() {
      return this.$store.state.songRequests.requestCode;
    },
    searchLoading() {
      return this.$store.state.songRequests.searchLoading;
    },
    requestLoading() {
      return this.$store.state.songRequests.requestLoading;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    features() {
      console.log(this.$store.state.featureObjects?.features);
      return this.$store.state.featureObjects?.features;
    },
  },
  methods: {
    clearSearchResults() {
      this.searchTerm = "";
      this.searchResults = [];
      this.searchTermSnapshot = "";
    },
    randomColor() {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    },
    saveSearch() {
      const parsed = JSON.stringify(this.recentSearches);
      localStorage.setItem("recentSearches", parsed);
    },
    itemImg(item) {
      return this.$store.getters.itemImg(item);
    },
    viewSongInfo(songObj) {
      this.$store.dispatch("viewSongInfo", songObj);
    },
    closeSnackbar() {
      this.snackbar = false;
      this.$store.dispatch("songRequests/setRequestStrings", {
        body: "",
        header: "",
      });
    },
    fetchSongs() {
      this.searchTermSnapshot = this.searchTerm;
      this.$store.dispatch("songRequests/fetchSongs", {
        term: this.searchTerm,
        admin: false,
        // field: "album",
        ...(this.features.feature_type === "ALBUM" && {
          field: this.features.feature_type.toLowerCase(),
        }),
      });
    },
    getTopRequested() {
      this.$store.dispatch("songRequests/getTopRequested", { field: "requestcount" });
    },
  },
  mounted() {
    this.getTopRequested();
    this.search = debounce(this.fetchSongs.bind(this), 1000);
    if (localStorage.getItem("recentSearches")) {
      const searches = JSON.parse(localStorage.getItem("recentSearches"));
      const filteredSearches = [];
      searches.forEach((search) => {
        const now = new Date();
        if (now.getTime() > search.expiry) {
          localStorage.removeItem(search);
        } else {
          filteredSearches.push(search);
        }
      });
      this.recentSearches = filteredSearches;
    }
    if (this.$route.params?.query) {
      this.searchTerm = this.$route.params.query;
    }
  },
};
</script>

<style lang="scss" scoped>
.song_list {
  background: #eee;

  &:first-child {
    border-top: 1px solid black;
  }

  &:nth-child(even) {
    background: #f8f8f8;
  }

  &:hover {
    background: #dbdbdb;
  }
}

#list_link {
  transition: all 0.5 ease-in-out;

  &:hover {
    color: blue !important;
    font-size: 1.1em;
  }
}
</style>
